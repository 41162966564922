// titan article-legacy-styles.less



/*
supporting imported wp/drupal style classes for:

- basic background box
- image left/right alignment
- facts boxes
*/



.vrtx-article-body {

  // background used to emphasize links to related articles etc

  p.has-background {
    background: var(--color-neutral-tint-95);
    padding: 30px;
    margin: 40px 0;
  }

  // image align left/right

  figure {
    &.alignleft,
    &.alignright {
      width: 300px;
    }
    @media (@screen-below-medium) {
      &.alignleft,
      &.alignright {
        max-width: 70vw;
        margin-right: auto;
        margin-left: auto;
      }
    }
    @media (@screen-medium) {
      &.alignleft,
      &.alignright {
        clear: both;
        margin: 10px 40px 40px 40px;
      }
      &.alignleft {
        float: left;
        margin-left: 0;
      }
      &.alignright {
        float: right;
        margin-right: 0;
      }
    }
    @media (@screen-large) {
      &.alignleft {
        margin-left: -100px;
      }
      &.alignright {
        margin-right: -50px;
      }
    }
  }

  // facts box

  .wp-block-titan-factsbox-block {
    background: var(--color-neutral-tint-95);
    font-size: var(--font-size-base);
    padding: 30px;
    margin: 40px 0;
    @media (@screen-large) {
      clear: both;
      padding: 40px;
      // alignment
      &.alignleft,
      &.alignright {
        margin: 10px 40px 40px 40px;
        width: 400px;
      }
      &.alignleft {
        float: left;
        margin-left: -200px;
      }
      &.alignright {
        float: right;
        margin-right: -150px;
      }
    }
    header {
      border-bottom: 1px solid var(--color-neutral-tint-90);
      padding-bottom: 5px;
      margin-bottom: 25px;
      h2,
      h3 {
        font-size: 2.4rem;
        margin: 0;
      }
    }
  }
}