// titan article.less



// introduction-image

.vrtx-introduction-image {
  display: revert;
  max-width: none;
  img {
    width: 100%;
    max-height: none;
    @media (@screen-large) {
      aspect-ratio: 16/9;
      object-fit: cover;
    }
  }
  .vrtx-imagetext {
    display: revert;
  }
}



// article page specific

#vrtx-structured-article {
  #breadcrumbs {
    margin-left: 0;
  }
  #vrtx-introduction-wrapper {
    display: grid;
  }
  .vrtx-introduction-image {
    order: -1;
    @media (@screen-large) {
      // escape content indent
      margin-left: -200px;
    }
  }
  .vrtx-introduction {
    margin-bottom: 30px;
    p {
      margin: 0;
    }
    strong {
      font-weight: inherit;
    }
  }
}



// authors and date

.vrtx-authors {
  font-weight: 600;
  margin-bottom: 50px;
  .vrtx-authors-prefix {
    font-weight: inherit;
    &::after {
      display: none;
    }
  }
}

.vrtx-date-info {
  margin-top: 3px;
  margin-bottom: 50px;
  @media (@screen-large) {
    margin-bottom: 75px;
  }
  .published-date-label {
    font-weight: normal;
    &::after {
      display: none;
    }
  }
}

.vrtx-authors + .vrtx-date-info {
  margin-top: -50px;
}



// tags. hidden, but used for JS detection for certain tag-labels

.vrtx-tags {
  display: none;
}



// article-body

.vrtx-article-body {
  border: none;
  padding: 0;
  margin: 0;

  // font upscale

  @media (@screen-medium) {
    font-size: 2rem;
    h2 {
      font-size: 3rem;
    }
    h3 {
      font-size: 2.6rem;
    }
  }

  // links

  a {
    color: var(--color-uio-red);
    text-decoration-thickness: 1px;
  }

  table {
    font-size: 1.8rem;
  }

  // box (used to emphasize links etc)

  .box {
    background: var(--color-neutral-tint-95);
    padding: 30px;
    margin: 40px 0;
    > *:last-child {
      margin: 0;
    }
  }

  // blockquote

  blockquote {
    margin: 60px 0;
    p:first-child {
      @media (@screen-medium) {
        font-size: 3.6rem;
      }
    }
  }

  // image-left/right

  @media (@screen-large) {
    .image-left {
      margin-left: -100px;
    }
    .image-right {
      margin-right: -50px;
    }
  }

  // vrtx-facts-container

  .vrtx-facts-container {
    font-size: var(--font-size-base);
    @media (@screen-large) {
      padding: 40px;
      &.vrtx-container-left {
        margin-left: -200px;
      }
      &.vrtx-container-right {
        margin-right: -150px;
      }
    }
    h2,
    h3 {
      &:first-child {
        border-bottom: 1px solid var(--color-neutral-tint-90);
        padding-bottom: 5px;
        margin-bottom: 25px;
      }
    }
  }
}



// fullscreen image

.vrtx-article-body .image-center {
  @media (@screen-large) {
    margin: 80px 0;
  }
}



// also-read (article related content)

.titan-also-read {
  max-width: 700px;
  border-top: 1px solid var(--color-neutral-tint-80);
  padding-top: 30px;
  margin-top: 100px;
  h2 {
    font-weight: normal;
  }
  .vrtx-feed {
    .items {
      li {
        box-shadow: none;
      }
      // duplicate handling (js detects if any list-item matches current article)
      li.duplicate {
        display: none;
      }
      // if duplicate present, last item in list is shown instead (feed fetches 5 items)
      &:not(.has-duplicate) {
        li:last-child {
          display: none;
        }
      }
      .vrtx-list-item-content {
        padding: 0;
        // if no image
        &:first-child {
          border-top: 1px solid var(--color-neutral-tint-80);
        }
        // tag/label
        &::before {
          margin-left: 0;
          padding: 5px 10px;
        }
      }
      .item-title {
        font-family: inherit;
        font-size: var(--font-size-small);
        font-weight: normal;
        line-height: inherit;
      }
      @media (@screen-medium) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      @media (@screen-below-medium) {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}



// newsletter-signup-module (used in article om-oss/nyhetsbrev.html)

.newsletter-signup-module {
  background: var(--color-neutral-tint-95);
  margin-top: 50px;
  padding: 30px;
  form {
    input,
    button {
      background: white;
    }
    input {
      display: block;
      width: 100%;
      margin: 5px 0 20px;
    }
  }
}