// titan local.less



// framework imports

@import "../../../../../../../lib/x1/css/x-0.less";

// local imports

@import "font-figtree.less";
@import "font-eb-garamond.less";
@import "article.less";
@import "article-legacy-styles.less";



// variables

:root {

  // framework variable overrides

  --font-size-base: 1.7rem;
  --font-size-small: 1.5rem;
  --font-size-tiny: 1.5rem;
  --space: 30px;

  // local variables

  --shadow: 0 0 4px rgba(0,0,0, .15);
  --serif: "EB Garamond", serif;
}



// general

body {
  font-family: "Figtree", sans-serif;
  font-size: var(--font-size-base);
}

// left main and menu not in use
#left-main,
#global-sub-menu,
#left-menu-same-level-folders {
  display: none;
}

// rss not in use
.vrtx-feed-link {
  display: none;
}

a {
  text-decoration-thickness: 1px;
  text-underline-offset: .05em;
}



// article tag/emneord used to control pages

// documentation pages

body[data-categories*=Internal],
body[data-categories*=Documentation] {
  // hide also-read articles at bottom of article page
  .titan-also-read {
    display: none;
  }
}

// newsletter signup page

body[data-categories*=Newsletter-page] {
  // hide publish date
  .vrtx-date-info {
    display: none;
  }
}



// serif font

h1,
h2,
.mixin-h2,
h3,
.mixin-h3 {
  font-family: var(--serif);
  font-weight: 600;
}

h1 {
  font-size: 6rem;
  line-height: 1.1;
  @media (@screen-below-medium) {
    font-size: 3.2rem;
  }
}

h2,
.mixin-h2 {
  font-size: 2.8rem;
  line-height: 1.2;
}

h3,
.mixin-h3 {
  line-height: 1.2;
}



// base font using lighter weight for bold

h4,
h5,
h6,
th,
strong,
b {
  font-weight: 600;
}



// header

#header {
  .logo {
    width: 240px;
    @media (@screen-below-medium) {
      width: 150px;
    }
  }
}

// English use same logo as NO.

html[lang=en] #header {
  .logo {
    background-image: url("../images/logo.svg");
  }
}



// main menu

.sidebar-menu-wrapper {
  li {
    &.root-folder, // forsiden
    &.alle-artikler,
    &.blogg {
      font-weight: bold;
      font-size: 2.4rem;
    }
  }
}



// breadcrumbs. include second to last on mobile to accomodate year folders. possible temp.

#breadcrumbs span:nth-last-child(3) {
  @media (@screen-below-medium) {
    display: inline-flex;
  }
}



// vrtx-collections (vrtx-subfolder-menu container)

#vrtx-collections {
  display: none;
}



// footer

#footer-wrapper {
  font-size: var(--font-size-small);
  .row,
  .footer-content {
    gap: 40px 5%;
  }
  .footer-content {
    @media (@screen-large) {
      grid-template-columns: 1.5fr 1fr 1fr;
    }
  }
  h2,
  .menu-label {
    font-family: inherit;
    font-weight: 600;
  }
  .newsletter-signup {
    background: var(--color-uio-red);
    padding: 50px 30px 30px;
    margin-top: -50px;
    margin-right: 10%;
    max-width: 400px;
    @media (@screen-below-medium) {
      margin-right: 0;
    }
    input {
      display: block;
      margin: 5px 0 20px;
      width: 100%;
    }
  }
}

// footer on error page (403, 404 etc)

body#error {
  #footer-wrapper {
    .newsletter-signup {
      display: none;
    }
  }
}



// vrtx-introduction

.vrtx-introduction {
  font-family: var(--serif);
  line-height: 1.3;
  font-size: 2.6rem;
  @media (@screen-medium) {
    font-size: 3.6rem;
  }
}



// blockquote

blockquote {
  &::before {
    content: "";
    display: block;
    height: 30px;
    background: url("../images/quote.svg") no-repeat;
    background-size: contain;
    margin-bottom: 25px;
  }
  p:first-child {
    font-family: var(--serif);
    font-size: 2.6rem;
    line-height: 1.3;
  }
}



// frontpage

// grid-container (outer row container)

.grid-container {
  margin-bottom: calc(2 * var(--space));
  &.row-all-colored {
    padding-top: var(--space);
    padding-bottom: var(--space);
  }
}

.row h2 {
  font-weight: normal;
}

#main .vrtx-subfolder-menu {
  background: none;
  padding: 0;
}



// feeds and lists

.vrtx-list-item-content {
  strong {
    font-weight: inherit;
  }
}



// article-listing

#vrtx-article-listing {

  // additional-content column
  #vrtx-additional-content {
    font-size: var(--font-size-small);
    @media (@screen-large) {
      margin-top: 115px;
    }
    h2,
    h3 {
      font-size: 2.4rem;
      margin: 0;
      &:first-child {
        margin: 0 0 20px 0;
      }
    }
  }
}



// tag-labels shown on articles with specific categories

.mixin-tag {
  display: block;
  width: fit-content;
  margin-bottom: 10px;
  padding: 7px 15px;
  background: var(--color-neutral-tint-opaque-95);
  color: var(--color-neutral-tint-25);
  font-family: var(--serif);
  font-size: var(--font-size-base);
  font-weight: 500;
  line-height: 1.5;
}

// in feeds

.vrtx-feed li[data-categories*=Kortnytt] {
  .vrtx-list-item-content {
    &::before {
      content: "Kortnytt";
      .mixin-tag;
    }
  }
}

// in full articles

#vrtx-structured-article{
  &[data-categories*=Kortnytt] {
    h1 {
      &::before {
        content: "Kortnytt";
        .mixin-tag;
      }
    }
  }
  &[data-categories*=Blogg] {
    h1 {
      &::before {
        content: "Blogg";
        .mixin-tag;
      }
    }
  }
}



// feeds

.vrtx-feed .items {
  li {
    background: white;
    box-shadow: var(--shadow);
  }
  .vrtx-list-item-content {
    padding: 0 15px 15px 15px;
    // label/tag
    &::before {
      margin-left: -15px;
    }
    // if image, label/tag will overlap it
    &:not(:first-child) {
      &::before {
        position: absolute;
        margin-top: -45px;
      }
    }
    // if no image
    &:first-child {
      padding-top: 15px;
    }
  }
  .published-date,
  .authors {
    font-size: inherit;
    color: var(--color-neutral-tint-25);
  }
  .authors {
    font-family: var(--serif);
    font-weight: 500;
  }
}



// feed without column-layout (vertical list with images on the left)
// note that on small screens this is identical to column version, with images on top

.vrtx-frontpage-box:not([class*=column-feed]) {
  @media (@screen-medium) {
    .vrtx-feed .items {
      li {
        padding: 15px;
      }
      .vrtx-image {
        display: grid; // control image height, or safari stretches to viewport
        margin: -15px 0 -15px -15px; // overflow padding
        img {
          margin: 0;
          height: 100%;
        }
      }
      .vrtx-list-item-content {
        padding: 0;
        // label/tag
        &::before {
          position: static;
          margin: 0 0 15px 0;
        }
      }
    }
    // if in half-half column split
    &[class*=half-box] {
      .vrtx-feed .items {
        .vrtx-image {
          max-width: 50%;
        }
      }
    }
  }
}



// featured feed item

.featured-feed-item .vrtx-feed {
  li:first-child {
    .vrtx-list-item-content {
      @media (@screen-medium) {
        padding: 30px 15px 30px 0;
        // if image
        &:not(:first-child) {
          &::before {
            position: static;
            margin-left: 0;
          }
        }
        // if no image
        &:first-child {
          padding-left: 15px;
          grid-column: 1/-1;
          max-width: 900px;
        }
      }
    }
    @media (@screen-large) {
      .item-title {
        line-height: 1.2;
      }
    }
  }
}



// more-links

.read-more,
.vrtx-more {
  font-weight: 600;
}



// most read (special manual list on front page)

.most-read {
  display: grid; // to control list height
  grid-template-rows: auto 1fr auto;
  box-shadow: var(--shadow);
  // box content
  .vrtx-box-content {
    position: relative; // relative to control list
    overflow: auto;
    min-height: 380px;
    scroll-behavior: smooth;
    // force macos webkit scrollbar
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 15px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      border: 4px solid white;
      background-color: rgba(0,0,0, .5);
    }
  }
  h2 {
    background: var(--color-neutral-tint-95);
    padding: 25px 15px;
    margin: 0;
  }
  ol {
    counter-reset: list-number;
    list-style: none;
    padding: 0 15px;
    margin: 0;
    // absolute pos to only take up space allowed by parent
    position: absolute;
    width: 100%;
  }
  li {
    counter-increment: list-number;
    display: flex;
    align-items: center;
    min-height: 120px;
    &:not(:last-child) {
      border-bottom: 1px solid var(--color-neutral-tint-90);
    }
    // numbers
    &::before {
      content: counter(list-number);
      flex-basis: 60px;
      flex-shrink: 0;
      font-family: var(--serif);
      font-size: 3.2rem;
      color: rgba(0,0,0, .5); // wcag 3:1 min size 24px
    }
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  // js generated button to scroll down
  .scroll-down {
    background: var(--color-neutral-tint-95);
    padding: 0;
    border: none;
    border-radius: 0;
    &::after {
      content: "";
      background: url("../images/chevron.svg") no-repeat center;
      transform: rotate(90deg);
      display: inline-block;
      height: 30px;
      width: 30px;
    }
    &:hover {
      background: var(--color-neutral-tint-90);
    }
  }
}



// daily quote (special manual area on front page)

.daily-quote {
  display: grid;
  align-content: center;
  background-color: var(--color-nearly-black);
  color: white;
  padding: calc(2 * var(--space));
  @media (@screen-below-large) {
    padding-right: 40px; // space for arrow (breaks outside)
    padding-left: var(--space);
  }
  p {
    margin: 0;
  }
  // daily quote blockquote
  blockquote {
    &::before {
      background-image: url("../images/quote-white.svg");
    }
    p:first-child {
      margin-bottom: 25px;
      @media (@screen-medium) {
        font-size: 3.2rem;
      }
      // if signature placed inside
      & + p {
        font-weight: 600;
      }
    }
    // if signature placed after
    & + p {
      font-weight: 600;
    }
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
      &::after {
        content: "";
        background: url("../images/arrow-white.svg") no-repeat center;
        background-size: contain;
        padding-right: 1em;
        margin-left: 10px;
      }
    }
  }
}



// search result page

#vrtx-searchview {
  #vrtx-show-advanced-search,
  #vrtx-advanced-search-wrapper {
    display: none; // hide advanced search
  }
  .result {
    h2 {
      color: var(--color-uio-red);
    }
  }
}
