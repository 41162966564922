// titan font-figtree.less



// italic

@font-face {
  font-family: 'Figtree';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/figtree/figtree-variable-italic.woff2") format('woff2');
}
@font-face {
  font-family: 'Figtree';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/figtree/figtree-variable-italic.woff2") format('woff2');
}
@font-face {
  font-family: 'Figtree';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/figtree/figtree-variable-italic.woff2") format('woff2');
}
@font-face {
  font-family: 'Figtree';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/figtree/figtree-variable-italic.woff2") format('woff2');
}
@font-face {
  font-family: 'Figtree';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/figtree/figtree-variable-italic.woff2") format('woff2');
}
@font-face {
  font-family: 'Figtree';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("../fonts/figtree/figtree-variable-italic.woff2") format('woff2');
}
@font-face {
  font-family: 'Figtree';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("../fonts/figtree/figtree-variable-italic.woff2") format('woff2');
}

// normal (non italic)

@font-face {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/figtree/figtree-variable.woff2") format('woff2');
}
@font-face {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/figtree/figtree-variable.woff2") format('woff2');
}
@font-face {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/figtree/figtree-variable.woff2") format('woff2');
}
@font-face {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/figtree/figtree-variable.woff2") format('woff2');
}
@font-face {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/figtree/figtree-variable.woff2") format('woff2');
}
@font-face {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("../fonts/figtree/figtree-variable.woff2") format('woff2');
}
@font-face {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../fonts/figtree/figtree-variable.woff2") format('woff2');
}